import axios from 'axios'

export function request(config) {
	const instance = axios.create({
		baseURL: "https://api.yunook.cn",
		timeout: 10000,
		headers: {
			'Content-Type': "application/x-www-form-urlencoded"
		},
		transformRequest: [function (data) {
			let ret = ''
			for (let it in data) {
				ret += it + '=' + data[it] + '&'
			}
			return ret
		}]
	})

	//相相应成功拦截
	instance.interceptors.response.use(res => {
		return res.data
	})
	return instance(config)
}
