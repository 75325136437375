import {request} from "@/network/request";

export function login(account, password) {
	return request({
		url: "/login.php",
		method: "POST",
		data: {
			account,
			password
		}
	})
}

export function getUserInfo(token) {
	return request({
		url: "/getUserInfo.php",
		method: "post",
		data: {
			token
		}
	})
}

export function getFightInfo(token) {
	return request({
		url: '/getFightInfo.php',
		method: "post",
		data: {
			token
		}
	})
}

export function addFightInfo(token, name, data) {
	return request({
		url: "/addFightInfo.php",
		method: "post",
		data: {
			token,
			name,
			data
		}
	})
}

export function register(account, password, name, mail) {
	return request({
		url: "/register.php",
		method: "post",
		data: {
			account,
			password,
			name,
			mail
		}
	})
}
